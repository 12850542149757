import React from 'react';
import CountUp from 'react-countup';
import { Box, Typography, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const StatsSection = () => {
  const stats = [
    { title: "Websites Built", end: 20 },
    { title: "People Helped", end: 150 },
  ];

  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <Box
      sx={{
        background: 'linear-gradient(145deg, #0f0f0f, #1c1c1c)', // Subtle gradient for the background
        padding: '50px 20px',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
     

      <Grid container spacing={4} justifyContent="center">
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
              transition={{ duration: 1 }}
              style={{
                background: 'linear-gradient(145deg, #1c1c1c, #292929)',
                borderRadius: '20px',
                padding: '30px',
                boxShadow:
                  '10px 10px 20px rgba(0, 0, 0, 0.6), -10px -10px 20px rgba(50, 50, 50, 0.2)',
                textAlign: 'center',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: '#42A5F5',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  fontSize: '1.5rem',
                }}
              >
                {stat.title}
              </Typography>
              {inView && (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    background: 'linear-gradient(90deg, #42A5F5, #90CAF9)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  <CountUp start={0} end={stat.end} duration={3} separator="," />
                </Typography>
              )}
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StatsSection;
