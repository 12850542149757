import React, { useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { SmartTicker } from 'react-smart-ticker';
import { Parallax } from 'react-scroll-parallax';
import '../styles/Services.css';
import { Link } from 'react-router-dom';

const services = [
  {
    title: 'Web Development',
    image: '/assets/WebDevelopment.png',
    description: 'Delivering high-performance websites built with modern frameworks to boost visibility, drive conversions, and dominate the digital landscape.',
    useCase: 'A local bakery needed an online presence to handle orders efficiently. Our interactive website helped them reach more customers and streamline operations.'
  },
  {
    title: 'Software Engineering',
    image: '/assets/SoftwareEngineering.png',
    description: 'Innovative software engineering solutions tailored to automate workflows and empower businesses with scalable, future-proof technology.',
    useCase: 'A logistics company required software to automate delivery scheduling. We built a custom solution that optimized their workflow and saved costs.'
  },
  {
    title: 'Project Management',
    image: '/assets/ProjectManagement.png',
    description: 'Expert project management services ensuring smooth execution, timely delivery, and goal alignment with client objectives for optimal results.',
    useCase: 'An event organizer struggled with deadlines. Our project management ensured everything ran smoothly, keeping tasks on schedule and stress-free.'
  },
  {
    title: 'Web Valet Services',
    image: '/assets/WebValet.png',
    description: 'Comprehensive web valet services, ensuring round-the-clock site optimization, monitoring, and maintenance for seamless online operations.',
    useCase: 'A retail store faced slow page speeds. Our valet services monitored and optimized their site 24/7, ensuring peak performance during high-traffic periods.'
  },
  {
    title: 'Cyber Security',
    image: '/assets/CyberSecurity.png',
    description: 'Advanced cyber security solutions that safeguard sensitive data, prevent threats, and ensure compliance with industry standards.',
    useCase: 'A startup experienced phishing attempts. We implemented robust security protocols to protect sensitive data and prevent breaches.'
  },
  {
    title: 'Data Management',
    image: '/assets/DataManagement.png',
    description: 'Secure data management services designed to streamline access, storage, and analytics, ensuring the integrity and availability of your data.',
    useCase: 'A healthcare provider needed help managing patient records. Our data management solutions ensured secure, compliant, and easy access to records.'
  },
  {
    title: 'E-Commerce Solutions',
    image: '../assets/ECommerce.png',
    description: 'End-to-end e-commerce solutions that enhance online stores with modern UX design, secure payments, and seamless product management.',
    useCase: 'A small business shifted online during the holidays. Our e-commerce platform enabled them to scale rapidly and maximize seasonal sales.'
  },
  {
    title: 'Mobile App Development',
    image: '/assets/MobileApp.png',
    description: 'Crafting responsive mobile apps with intuitive interfaces to provide top-tier experiences for both iOS and Android platforms.',
    useCase: 'A fitness coach wanted to offer virtual classes. We developed a mobile app that connected them with clients anytime, anywhere.'
  },
  {
    title: 'AI and Machine Learning',
    image: '/assets/AI.png',
    description: 'Empowering businesses with cutting-edge AI and machine learning technologies that drive intelligent automation and innovation.',
    useCase: 'An online retailer needed personalized recommendations. Our AI solution increased customer engagement and boosted revenue.'
  }
];

const Services = () => {

  const tickerRef = useRef();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ width: "100vw", color: '#fff' }}
    >
      <Parallax speed={2}>
      <Typography
      id="#services"
  variant="h2"
  align="center"
  gutterBottom
  sx={{
    marginBottom: '40px',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'white',
    animation: 'gradientShift 6s infinite',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)', // Add shadow for better visibility
    '@keyframes gradientShift': {
      '0%': {
        background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
      },
      '25%': {
        background: 'linear-gradient(45deg, #FF5722, #FFC107)',
      },
      '50%': {
        background: 'linear-gradient(45deg, #8BC34A, #4CAF50)',
      },
      '75%': {
        background: 'linear-gradient(45deg, #673AB7, #3F51B5)',
      },
      '100%': {
        background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
      },
    },
  }}
>
          Our Services
        </Typography>
      </Parallax>
      
      <Box sx={{ overflow: 'hidden', maxWidth: '100%' }}>
      <SmartTicker
        forwardedRef={tickerRef}
        speed={80}
        pauseOnHover={false}
        infiniteScrollView={true}
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              style={{ display: 'inline-block', marginRight: '50px' }}
            >
              <Box
                className="box"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  borderRadius: '10px',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                  width: '80vw',
                  height: '700px',
                  textAlign: 'center',
                  backgroundColor: '#333',
                  color: '#fff',
                  overflow: 'hidden',
                }}
              >
                <Box
                  component="img"
                  src={service.image}
                  alt={service.title}
                  sx={{
                    width: { xs: '150px', md: '200px' },
                    height: { xs: '150px', md: '200px' },
                    marginBottom: '10px',
                    filter: 'grayscale(100%)',
                    transition: 'filter 0.3s ease-in-out',
                    '&:hover': { filter: 'grayscale(0%)' },
                  }}
                />
               <Typography
  variant="h5"
  gutterBottom
  sx={{
    overflow: 'hidden',
    whiteSpace: 'normal', // Allow text wrapping
    wordWrap: 'break-word', // Ensure words break if they are too long
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  }}
>
  {service.title}
</Typography>
<Typography
  variant="body2"
  sx={{
    color: '#ccc',
    marginBottom: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal', // Allow text wrapping
    wordWrap: 'break-word', // Ensure words break if they are too long
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 'unset', // Allow unlimited lines
  }}
>
  {service.description}
</Typography>
<Typography
  variant="subtitle2"
  sx={{
    fontWeight: 600,
    fontStyle: 'italic',
    overflow: 'hidden',
    whiteSpace: 'normal', // Allow text wrapping
    wordWrap: 'break-word', // Ensure words break if they are too long
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  }}
>
  Use case: {service.useCase}
</Typography>

<Button component={Link} to="/services" sx={{ background: "white" , marginTop: 10 , fontWeight: "bold" }}>Learn More!</Button>
              </Box>
            </motion.div>
          ))}
        </SmartTicker>
      </Box>
     
    </motion.div>
   
  );
};

export default Services;
