import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { keyframes } from '@emotion/react';

// Keyframes for glitch effects
const glitchEffect = keyframes`
  0% { transform: translate(0, 0); opacity: 1; }
  20% { transform: translate(-2px, -2px); opacity: 0.8; }
  40% { transform: translate(2px, 2px); opacity: 0.9; }
  60% { transform: translate(-2px, 2px); opacity: 0.7; }
  80% { transform: translate(2px, -2px); opacity: 0.9; }
  100% { transform: translate(0, 0); opacity: 1; }
`;

const CTAComponent = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new URLSearchParams({
      'form-name': 'newsletter', // Matches the form name
      email,
    });

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData.toString(),
      });
      setSuccess(true); // Display success feedback
      setEmail(''); // Clear the email input field
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <Box
      sx={{
        background: 'radial-gradient(circle, #000000, #1f1f1f)',
        color: '#00ff99',
        py: 10,
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(90deg, transparent 50%, rgba(0, 255, 153, 0.1) 50%)',
          animation: `${glitchEffect} 3s infinite`,
          mixBlendMode: 'overlay',
        },
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h3"
          fontWeight="bold"
          gutterBottom
          sx={{
            textShadow: '0 0 10px #00ff99, 0 0 20px #00ff99',
          }}
        >
          Stay in the Loop
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: '1.2rem',
            textShadow: '0 0 5px #00ff99',
          }}
        >
          Sign up to receive cutting-edge updates and insights in the tech world.
        </Typography>
        <form
          onSubmit={handleSubmit}
          name="newsletter" // Form name for Netlify
          method="POST"
          data-netlify="true" // Enables Netlify form handling
        >
          {/* Hidden input required for Netlify forms */}
          <input type="hidden" name="form-name" value="newsletter" />
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <TextField
                fullWidth
                type="email"
                name="email" // Form field name for Netlify
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                variant="outlined"
                sx={{
                  backgroundColor: '#1f1f1f',
                  borderRadius: '8px',
                  input: { color: '#00ff99' },
                  border: '2px solid #00ff99',
                  '&:focus-within': {
                    boxShadow: '0 0 10px #00ff99',
                  },
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#00ff99',
                  color: '#000',
                  px: 4,
                  py: 1.5,
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  '&:hover': {
                    backgroundColor: '#00cc7a',
                    animation: `${glitchEffect} 0.2s`,
                  },
                  boxShadow: '0 0 15px #00ff99, 0 0 25px #00ff99',
                }}
              >
                Subscribe
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Snackbar
        open={success}
        autoHideDuration={5000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Your subscription was successful. Welcome aboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CTAComponent;
