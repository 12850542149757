import React, { useState } from 'react';
import { Container, Typography, Box, Button, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';

const projects = [
  {
    title: 'All PC Repair',
    description: 'Expert IT solutions for homes and businesses in Hampton Roads.',
    link: 'https://allpcrepairva.com',
    mobileScreenshot: 'ClientLogos/AllPcRepairLogo.png',
    alt: 'All PC Repair website screenshot',
    bgColor: 'black',
  },
  {
    title: 'Family Reunite Network',
    description: 'Compassionate support for families facing separation.',
    link: 'https://familyreunitenetwork.com',
    mobileScreenshot: 'ClientLogos/FamilyReuniteNetworkLogo.jpg',
    alt: 'Family Reunite Network website screenshot',
    bgColor: 'white',
  },
  {
    title: 'Valet Ninjas',
    description: 'Top-tier valet services for seamless parking logistics.',
    link: 'https://www.valetninjas.website',
    mobileScreenshot: 'ClientLogos/ValetNinjasLogo.png',
    alt: 'Valet Ninjas website screenshot',
    bgColor: 'white',
  },
  {
    title: 'Scott\'s Electric',
    description: 'Trusted electrical services for residential and commercial needs.',
    link: 'https://www.scottselectric.pro',
    mobileScreenshot: 'ClientLogos/ScottsElectricLogo.png',
    alt: 'Scott\'s Electric website screenshot',
    bgColor: 'white',
  },
];

const Portfolio = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundColor: '#121212',
        padding: '50px 0',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Parallax scale={[0.85, 1.2]} easing="easeInQuad">
        <Container maxWidth="sm">
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{
              marginBottom: '40px',
              fontSize: '2.5rem',
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'white',
              animation: 'gradientShift 6s infinite',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
              '@keyframes gradientShift': {
                '0%': {
                  background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                },
                '25%': {
                  background: 'linear-gradient(45deg, #FF5722, #FFC107)',
                },
                '50%': {
                  background: 'linear-gradient(45deg, #8BC34A, #4CAF50)',
                },
                '75%': {
                  background: 'linear-gradient(45deg, #673AB7, #3F51B5)',
                },
                '100%': {
                  background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                },
              },
            }}
          >
            Featured Websites
          </Typography>
          <Box
            sx={{
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
              height: '400px',
              borderRadius: '20px',
            }}
          >
            {projects.map((project, index) => (
              <Box
                key={index}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: `${(index - currentIndex) * 100}%`,
                  transition: 'left 0.5s ease-in-out',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: 'linear-gradient(145deg, #1c1c1c, #2a2a2a)',
                  padding: '30px',
                  borderRadius: '20px',
                  textAlign: 'center',
                  boxShadow:
                    '10px 10px 20px rgba(0, 0, 0, 0.5), -10px -10px 20px rgba(50, 50, 50, 0.2)',
                }}
              >
                <motion.img
                  src={project.mobileScreenshot}
                  alt={project.alt}
                  loading="lazy"
                  style={{
                    maxHeight: '120px',
                    objectFit: 'contain',
                    width: '100%',
                    marginBottom: '20px',
                  }}
                  whileHover={{ scale: 1.1 }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    marginBottom: '10px',
                  }}
                >
                  {project.title}
                </Typography>
                <Divider
                  sx={{
                    margin: '10px auto',
                    width: '50%',
                    backgroundColor: '#fff',
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: '#ccc',
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    marginBottom: '20px',
                  }}
                >
                  {project.description}
                </Typography>
                <Button
                  variant="contained"
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    background: 'linear-gradient(45deg, #42A5F5, #90CAF9)',
                    color: '#fff',
                    fontSize: '1rem',
                    padding: '10px 20px',
                    borderRadius: '25px',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #90CAF9, #42A5F5)',
                    },
                  }}
                >
                  Visit Site
                </Button>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <Button onClick={prevSlide} variant="outlined" color="primary">
              Previous
            </Button>
            <Button onClick={nextSlide} variant="outlined" color="primary">
              Next
            </Button>
          </Box>
        </Container>
      </Parallax>
    </motion.div>
  );
};

export default Portfolio;
